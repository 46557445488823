.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px; }

.arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px 12px;
  width: 42px;
  border: 1px solid transparent;
  box-sizing: border-box; }
  .arrow .circle {
    width: 2px;
    height: 2px;
    border: 2px solid #4bb051;
    border-radius: 50%; }
  .arrow .dashed {
    height: 24px;
    border-left: 2px dashed rgba(75, 176, 81, 0.4);
    opacity: 0.5; }
  .arrow .triangle {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #4bb051; }
