.card {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08); }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield; }
