.wrapper {
  position: relative;
  padding: 16px; }

.folder {
  margin: 16px 0;
  border: 1px solid #ededed;
  border-radius: 4px; }
  .folder:first-child {
    margin-top: 0; }
  .folder .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px 2px 16px;
    background-color: #fafafa;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #ededed;
    min-height: 40px; }
    .folder .header .left,
    .folder .header .right {
      position: relative;
      display: flex;
      align-items: center; }
    .folder .header .title {
      margin-right: 8px;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87); }
    .folder .header .input {
      margin-right: 8px;
      padding: 0 8px;
      width: 100%;
      height: 24px;
      border: 1px solid #4caf50;
      border-radius: 4px;
      outline: none; }
      .folder .header .input:active, .folder .header .input:focus {
        border: 1px solid #4caf50; }
    .folder .header .paper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 500px;
      padding: 12px 16px;
      top: 26px;
      right: 0px;
      background-color: white;
      z-index: 1; }
  .folder .body {
    border-radius: 0 0 4px 4px; }
    .folder .body .uploadDisableHover:hover {
      background-color: white !important; }
    .folder .body .upload {
      position: relative;
      display: flex;
      align-items: center;
      padding: 14px 16px;
      color: rgba(0, 0, 0, 0.6); }
      .folder .body .upload.disabled {
        opacity: 0.5; }
      .folder .body .upload:hover {
        background-color: rgba(0, 0, 0, 0.04); }
      .folder .body .upload.disabled:hover {
        background-color: white; }
      .folder .body .upload .meta {
        margin-left: 11px; }
      .folder .body .upload .title {
        font-size: 14px; }
      .folder .body .upload .size {
        font-size: 10px; }
      .folder .body .upload .input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0%;
        opacity: 0;
        cursor: pointer; }
        .folder .body .upload .input.disabled {
          cursor: default; }
  .folder .icon {
    margin-left: -5px; }

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-top: 1px solid #ededed; }
  .item:first-child {
    border-top: 0; }
  .item .left,
  .item .right {
    position: relative;
    display: flex;
    align-items: center; }
  .item .left {
    flex-grow: 1;
    overflow: hidden; }
  .item .right {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-left: 8px;
    min-width: 150px; }
  .item .right > button {
    margin-left: 6px; }
  .item .icon {
    margin-left: -5px; }
  .item .meta {
    margin-left: 11px;
    width: 100%; }
  .item .title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.86);
    overflow-wrap: break-word; }
  .item .progress {
    margin-top: 2px; }
  .item .size {
    margin-top: 1px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.56); }
  .item .paper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    padding: 12px 16px;
    top: 36px;
    right: 0px;
    background-color: white;
    z-index: 1; }
  .item .error {
    margin-top: 1px;
    font-size: 10px;
    color: #ee6723; }

.createFolder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border: 1px dashed #ededed;
  border-radius: 4px;
  cursor: pointer; }
  .createFolder:hover {
    background-color: rgba(0, 0, 0, 0.04); }

.flex1 {
  flex: 1; }

.border_t {
  border-top: 1px solid #ededed; }

.invisible {
  visibility: hidden; }
