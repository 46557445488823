.modal {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.paper {
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex; }
  .wrapper:focus {
    outline: none; }

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-indent: -9999px; }

.actionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.contentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 70vw;
  max-width: 1024px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; }

.buttonWrapper {
  display: flex;
  justify-content: flex-end; }

.videoPlayer {
  position: relative; }
