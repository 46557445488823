.contentRight {
  width: 100%;
  min-width: 260px;
  max-width: 350px;
  background: #fff;
  border-left: 1px solid #EDEDED; }

.contentRightHead {
  background-color: #FAFAFA;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  padding: 0px 16px; }

.contentRightDetail {
  padding: 16px; }

.resetTransform {
  transform: initial !important; }
