.contentLeft {
  background: #fff;
  border-right: 1px solid #EDEDED; }

.contentLeftHead {
  background-color: #FAFAFA;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  padding: 0px 16px; }

.contentLeftDetail {
  padding: 36px 16px; }

.contentLeftDetailBox {
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 16px 24px; }

.resetTransform {
  transform: initial !important; }
