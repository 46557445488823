.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.cardList {
  max-height: 200px;
  overflow-y: auto; }

.tabWrapper {
  display: flex;
  border: 1px solid #4bb051;
  border-radius: 4px;
  overflow: hidden; }

.tabActiveBtn {
  padding: 6px 20px;
  color: #fff;
  background-color: #4bb051;
  cursor: not-allowed; }

.tabInactiveBtn {
  padding: 6px 20px;
  color: #4bb051;
  background-color: #fff;
  cursor: pointer; }

.filterList {
  min-height: 116px !important;
  max-height: 234px !important; }

.dateTitle {
  margin: 0px 16px;
  font-size: 1.75em;
  font-weight: 700;
  color: #4bb051; }

.dateButton {
  display: flex;
  align-items: center;
  width: 41.19px;
  height: 35.06px;
  color: rgba(0, 0, 0, 0.56);
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 0.25em;
  outline: none; }
  .dateButton:hover {
    background-color: #f0f0f0; }
  .dateButton:not(:disabled) {
    cursor: pointer; }

.dateHighlight {
  color: #4bb051; }

.dateButtonGroup {
  display: flex;
  color: rgba(0, 0, 0, 0.56);
  border: 1px solid #f0f0f0;
  border-radius: 0.25em; }
  .dateButtonGroup > button {
    width: 34.19px;
    height: 34.19px;
    border: 0;
    background-color: white;
    outline: none; }
    .dateButtonGroup > button:hover {
      background-color: #f0f0f0; }
    .dateButtonGroup > button:not(:disabled) {
      cursor: pointer; }
  .dateButtonGroup > .middle {
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0; }

.disabled {
  opacity: 0.6; }

.labelRoot {
  margin-right: 0px; }

.labelText {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.56); }

.icon {
  display: flex;
  align-items: center; }
  .icon svg {
    width: 100%; }

.autocompleteDisabled {
  color: rgba(0, 0, 0, 0.26) !important; }
  .autocompleteDisabled fieldset {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 4px; }

.groupLabel {
  position: sticky;
  top: -8px;
  padding: 8px 16px;
  list-style: none;
  line-height: 24px;
  background-color: #fff;
  z-index: 1;
  box-sizing: border-box; }

.groupItem {
  padding: 0; }

.processAutocompleteGroupingInputPopper {
  width: 380px !important;
  left: 40px !important; }

.autolink {
  word-break: break-all; }
  .autolink a {
    color: #576b95; }

.filterOption {
  padding: 0px 16px 0px 6px !important; }

.filterOption2 {
  padding: 4px 16px 4px 6px !important; }

.filterOption3 {
  padding-left: 0px !important; }

.searchTextFilterWrapper {
  position: relative; }

.searchTextFilterClear {
  position: absolute;
  top: 0px;
  right: 0px; }

.searchTextFilterPopper {
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  height: 238px;
  background-color: white;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  z-index: 2; }

.searchTextFilterPopperUl {
  overflow-y: auto;
  height: 100%;
  margin: 0;
  padding: 0; }

.searchTextFilterPopperLi {
  list-style: none;
  height: 36px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .searchTextFilterPopperLi:hover {
    background-color: rgba(0, 0, 0, 0.04); }

.searchTextFilterMarginDense {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }
