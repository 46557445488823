.buttonGroup {
  position: relative;
  background-color: transparent;
  cursor: pointer; }
  .buttonGroup button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 1rem;
    margin-top: 0;
    background-color: white; }
    .buttonGroup button:hover, .buttonGroup button:disabled {
      background-color: white; }
