.img {
  width: 100%; }

.iframe {
  width: 100%;
  min-height: 1200px;
  border: 0; }

.miniResumeWrap {
  width: 100%;
  font-size: 14px; }
  .miniResumeWrap .content {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 1rem; }
  .miniResumeWrap .item {
    width: 100%;
    padding-top: 16px; }
  .miniResumeWrap .title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500; }
  .miniResumeWrap .onewrap {
    width: 100%;
    position: relative; }
  .miniResumeWrap .one {
    display: flex; }
  .miniResumeWrap .spe {
    margin-top: 16px;
    width: 100%; }
  .miniResumeWrap .exwrap {
    margin-top: 16px;
    width: 40%; }
  .miniResumeWrap .exwrap.active {
    width: 50%; }
  .miniResumeWrap .extitle {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6); }
  .miniResumeWrap .exname {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    word-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    padding-right: 24px; }
  .miniResumeWrap .fill {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.87);
    opacity: 0.12;
    margin-top: 16px; }
  .miniResumeWrap .imgwrap {
    width: 96px;
    height: 96px;
    position: absolute;
    top: 16px;
    right: 0;
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
  .miniResumeWrap .img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }
  .miniResumeWrap .li {
    margin-top: 16px; }
  .miniResumeWrap .edu {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px; }
  .miniResumeWrap .detail {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px; }
  .miniResumeWrap .common {
    margin-right: 16px; }
  .miniResumeWrap .word {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    word-wrap: break-word;
    white-space: pre-line; }

.foundNoResume {
  margin-top: 16px;
  text-align: center; }
  .foundNoResume .image {
    margin: auto; }
  .foundNoResume .title {
    margin-top: 1.5rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 16px; }
  .foundNoResume .subtitle {
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px; }
  .foundNoResume .uploadButton {
    padding: 10px 22px;
    font-size: 14px;
    background-color: #4caf50;
    border-radius: 4px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    margin-bottom: 100px; }

.resume {
  position: relative; }
  .resume .wrapper {
    position: sticky;
    bottom: 1rem;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: space-around;
    margin: 1rem; }
  .resume:hover .wrapper {
    display: flex; }
  .resume .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    cursor: pointer; }
  .resume .deleteButtonWrapper {
    position: absolute;
    top: -4.25rem;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #fff; }

.attachmentResume {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  text-align: left; }
  .attachmentResume .head {
    height: 40px;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #ededed;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    line-height: 16px;
    padding: 12px 15px; }
  .attachmentResume .body {
    width: calc(100% - 32px);
    margin: 12px 16px;
    display: flex;
    justify-self: flex-start;
    align-items: flex-start; }
    .attachmentResume .body .bodyHalf {
      width: 50%; }
      .attachmentResume .body .bodyHalf .form {
        font-size: 12px;
        font-weight: 400;
        width: calc(100% - 16px); }
        .attachmentResume .body .bodyHalf .form .title {
          line-height: 16px;
          color: rgba(0, 0, 0, 0.87); }
        .attachmentResume .body .bodyHalf .form .desc {
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 16px;
          word-break: break-all; }
