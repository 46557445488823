.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.type1 {
  color: #4b84ff; }

.type2 {
  color: #6bc6c6; }

.type3 {
  color: #834dea; }

.parent:hover .child {
  display: block; }

.child {
  display: none; }
