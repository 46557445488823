.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column; }

.header {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #ededed;
  border-left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap; }
  .header .back {
    padding: 12px 14px; }

.sticky {
  position: sticky;
  top: 56px;
  z-index: 2; }

.content {
  position: relative;
  overflow-y: auto;
  flex: 1;
  background-color: white;
  border-right: 1px solid #ededed; }
