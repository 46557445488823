.editFormWrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem; }

.editFormButton {
  display: flex;
  justify-content: center;
  align-items: center; }

.selectorButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.86);
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 5px 12px;
  min-width: 360px;
  font-size: 14px;
  border-radius: 4px; }
  .selectorButton:hover {
    border: 1px solid rgba(0, 0, 0, 0.26); }
  .selectorButton .positionName {
    display: flex; }
    .selectorButton .positionName .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 180px; }
  .selectorButton .startTime {
    margin-left: 8px;
    color: #9b9b9b; }
  .selectorButton .icon {
    font-size: 18px;
    color: #9b9b9b; }

.pointer {
  cursor: pointer; }

.applicationList {
  position: absolute;
  margin: 0;
  min-width: 360px;
  top: 41px;
  left: 0px;
  font-size: 14px; }
  .applicationList .assignPosition {
    color: #4bb051;
    padding: 12px 16px; }
  .applicationList .ul {
    margin: 0;
    padding: 0;
    max-height: 18rem;
    overflow-y: auto;
    cursor: pointer; }
    .applicationList .ul .li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: 12px 16px;
      box-sizing: border-box;
      position: relative; }
      .applicationList .ul .li:hover {
        background-color: rgba(0, 0, 0, 0.04); }

.iconLabel {
  display: flex; }
  .iconLabel .icon {
    display: flex;
    align-items: center; }
  .iconLabel .label {
    flex-shrink: 0;
    margin-left: 4px;
    color: #666666; }
