.contentMain {
  display: flex;
  flex: 1; }

.contentLeft {
  flex: 1;
  min-width: 490px;
  height: 100%;
  background-color: white; }

.contentRight {
  width: 30%;
  min-width: 326px;
  max-width: 350px;
  margin-left: 1rem;
  background-color: #F9F9F9; }
