.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 8px;
  border-radius: 4px;
  background: #fefefe;
}

.editor:hover {
  border: 1px solid #4caf50;
}

.editor:focus-within {
  border: 1px solid #4caf50;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 80px;
}

.editor :global(.public-DraftStyleDefault-block) {
  margin: 0px;
  line-height: 22px;
}

.editor :global(.DraftEditor-editorContainer) {
  z-index: unset;
}
