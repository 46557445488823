.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1rem; }

.controller {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.video {
  width: 20vw;
  max-width: 300px;
  height: calc(20vw / 9 * 16);
  max-height: calc(300px / 9 * 16);
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.2);
  background-color: black;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover; }

.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 16px;
  color: white; }

.aiResult {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  font-size: 12px;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.8); }
