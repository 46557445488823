.wrapper {
  position: relative;
  min-height: 70vh; }

.notFinish {
  display: flex;
  justify-content: center;
  padding: 16px;
  color: #e99e23;
  background-color: #fff7e0; }

.ai_wrapper {
  position: relative;
  margin: 12px;
  overflow: hidden; }
  .ai_wrapper:hover > .more {
    opacity: 1; }
  .ai_wrapper > .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 12px;
    padding-bottom: 0;
    font-size: 12px;
    cursor: pointer; }
    .ai_wrapper > .card .left {
      color: #4bb051; }
      .ai_wrapper > .card .left .scoreWrapper {
        position: relative;
        display: inline-block; }
      .ai_wrapper > .card .left .score {
        font-family: 'DIN Alternate Bold';
        font-size: 28px;
        font-weight: bold; }
      .ai_wrapper > .card .left .fen {
        margin-left: 4px; }
      .ai_wrapper > .card .left .recommend {
        position: absolute;
        top: -5px;
        right: -25px; }
      .ai_wrapper > .card .left .goToSetting {
        margin-left: 4px;
        line-height: 18px;
        color: #4bb051;
        border-bottom: 1px solid rgba(75, 176, 81, 0.8);
        font-weight: 500;
        cursor: pointer; }
    .ai_wrapper > .card .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .ai_wrapper > .card .right .rank {
        display: flex;
        align-items: center;
        color: rgba(75, 176, 81, 0.8);
        cursor: pointer; }
      .ai_wrapper > .card .right .rankNumber {
        margin-left: 4px;
        font-family: 'DIN Alternate Bold';
        font-size: 20px;
        font-weight: bold; }
      .ai_wrapper > .card .right .link {
        display: flex;
        align-items: center;
        margin-top: 6px;
        color: rgba(75, 176, 81, 0.8);
        font-weight: bold;
        cursor: pointer; }
      .ai_wrapper > .card .right .icon {
        width: 16px;
        height: 16px; }
  .ai_wrapper .more {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 2px;
    font-size: 12px;
    height: 26px;
    color: #4bb051;
    cursor: pointer;
    opacity: 0.6; }
    .ai_wrapper .more:hover {
      opacity: 1; }
  .ai_wrapper .full_result {
    padding: 12px;
    padding-top: 0; }
    .ai_wrapper .full_result .card {
      margin-top: 24px;
      font-size: 12px;
      line-height: 12px; }
      .ai_wrapper .full_result .card .title {
        position: relative;
        display: inline-block;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        color: #333333; }
        .ai_wrapper .full_result .card .title .border {
          position: absolute;
          bottom: 1px;
          width: 100%;
          height: 4px;
          background: linear-gradient(97deg, #b0ffb5 0%, #4bb051 100%);
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
          border-radius: 2px;
          opacity: 0.7; }
      .ai_wrapper .full_result .card .list {
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.56); }
        .ai_wrapper .full_result .card .list .subtitle {
          margin-bottom: 12px;
          color: rgba(0, 0, 0, 0.56); }
        .ai_wrapper .full_result .card .list .item {
          margin-top: 8px;
          color: rgba(75, 176, 81, 0.8); }
          .ai_wrapper .full_result .card .list .item:first-child {
            margin-top: 0; }
      .ai_wrapper .full_result .card .radar {
        position: relative;
        margin: auto;
        margin-top: 1rem;
        width: 400px;
        height: 400px; }
      .ai_wrapper .full_result .card .score {
        font-family: 'DIN Alternate Bold';
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
        color: #4bb051; }
      .ai_wrapper .full_result .card .label {
        margin-top: 4px;
        font-size: 12px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.56); }
      .ai_wrapper .full_result .card .top {
        position: absolute;
        top: -1rem;
        left: 200px;
        transform: translateX(-50%);
        text-align: center; }
      .ai_wrapper .full_result .card .middleLeft {
        position: absolute;
        top: 130px;
        left: 0px;
        text-align: right; }
      .ai_wrapper .full_result .card .middleRight {
        position: absolute;
        top: 130px;
        right: -20px;
        text-align: left; }
      .ai_wrapper .full_result .card .bottomLeft {
        position: absolute;
        bottom: 20px;
        left: 50px;
        text-align: right; }
      .ai_wrapper .full_result .card .bottomRight {
        position: absolute;
        bottom: 20px;
        right: 50px;
        text-align: left; }
      .ai_wrapper .full_result .card .bar {
        margin-top: 16px; }
      .ai_wrapper .full_result .card .disabled {
        color: #dcdcdc; }
      .ai_wrapper .full_result .card .hidden {
        visibility: hidden; }
  .ai_wrapper .ball1 {
    position: absolute;
    bottom: -40px;
    left: -30px; }
  .ai_wrapper .ball2 {
    position: absolute;
    bottom: -5px;
    left: 60px; }
  .ai_wrapper .ball3 {
    position: absolute;
    top: -30px;
    right: 20px; }

.ai_wrapper.closed {
  background: linear-gradient(180deg, #effff1 0%, #f8fff8 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06); }

.ai_wrapper.open {
  border-radius: 4px;
  border: 1px solid rgba(75, 176, 81, 0.1);
  background: linear-gradient(180deg, #f7fff7 0%, white 100%, rgba(244, 255, 245, 0) 100%); }

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 12px; }
  .toggleButton .label {
    cursor: pointer; }

.emptyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20% 0;
  color: #c3c3c3; }

.line {
  margin: 12px;
  border-top: 1px dashed rgba(75, 176, 81, 0.1); }

.aiResultCard {
  margin: 12px;
  padding: 12px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px; }
  .aiResultCard .flex {
    display: flex;
    align-items: center; }
  .aiResultCard .header {
    display: flex;
    justify-content: space-between; }
    .aiResultCard .header .titleLine {
      width: 4px;
      height: 16px;
      background: linear-gradient(180deg, #b0ffb5 0%, #4bb051 100%);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
      border-radius: 2px; }
    .aiResultCard .header .title {
      margin-left: 6px;
      font-size: 16px;
      font-weight: 500; }
    .aiResultCard .header .titleInfo {
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #c6c6c6; }
    .aiResultCard .header .collapse {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -12px;
      padding: 12px;
      font-size: 12px;
      color: #576b95;
      cursor: pointer; }

.aiResultCardItem {
  margin: 16px 0px; }

.spin {
  animation: spin 1s linear 2; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.scoreBigBall {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden; }
  .scoreBigBall .score {
    font-family: 'DIN Alternate Bold';
    font-size: 20px;
    font-weight: bold; }

.waves {
  position: absolute;
  bottom: 0;
  width: 200%;
  height: 50%; }

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite; }

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s; }

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s; }

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s; }

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s; }

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0); }
  100% {
    transform: translate3d(85px, 0, 0); } }

.scoreInChart {
  margin-right: 2px;
  font-family: 'DIN Alternate Bold';
  font-size: 18px;
  font-weight: bold; }

.labels1 {
  padding: 16px 0px; }

.label1 {
  line-height: 26px; }

.labels2 {
  padding: 16px 0px; }

.label2 {
  line-height: 33px; }

.labels3 {
  padding: 15px 0px; }

.label3 {
  line-height: 36px; }

.labels4 {
  padding: 16px 0px; }

.label4 {
  line-height: 37px; }

.labels5 {
  padding: 14px 0px; }

.label5 {
  line-height: 38px; }

.labels6 {
  padding: 15px 0px; }

.label6 {
  line-height: 38px; }
