.addTagDialog {
  width: 296px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 4px 0px; }
  .addTagDialog .editTagName {
    padding: 0px 16px;
    margin: 10px 0; }
    .addTagDialog .editTagName .MuiOutlinedInput-root {
      margin: 10px 0; }
      .addTagDialog .editTagName .MuiOutlinedInput-root .MuiOutlinedInput-input {
        margin: 10px 0; }
  .addTagDialog .tagListWrap {
    width: 100%;
    max-height: 198px;
    flex: 1;
    display: flex;
    flex-direction: column; }
    .addTagDialog .tagListWrap .ellipsis {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all; }
    .addTagDialog .tagListWrap .tagListTitle {
      display: flex;
      align-items: center;
      padding: 6px 16px;
      cursor: pointer; }
      .addTagDialog .tagListWrap .tagListTitle:hover {
        background-color: rgba(0, 0, 0, 0.04); }
    .addTagDialog .tagListWrap > ul {
      padding: 0;
      margin: 0;
      list-style: none;
      overflow-y: scroll; }
      .addTagDialog .tagListWrap > ul li {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0px 4px; }
        .addTagDialog .tagListWrap > ul li:hover {
          background-color: rgba(0, 0, 0, 0.04); }
