.wrapper {
  position: relative; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem; }

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer; }
  .item:hover {
    background-color: #f9faf9; }
  .item.active, .item:hover.active {
    background-color: rgba(76, 175, 80, 0.06);
    border-color: #4caf50; }
  .item .departmentName,
  .item .positionID {
    color: rgba(0, 0, 0, 0.6); }
