.title {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.56);
  vertical-align: middle;
  text-align: justify;
  display: flex;
  align-items: center; }

.title::before {
  content: '';
  display: inline-block;
  margin-right: 6px;
  width: 2px;
  height: 14px;
  background: linear-gradient(360deg, #4bb051 0%, #5fc766 100%); }
