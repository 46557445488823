.wrapper {
  position: relative;
  padding: 16px; }

.panel {
  margin-top: 16px;
  border: 1px solid #ededed;
  border-radius: 4px; }
  .panel:first-child {
    margin-top: 0; }
  .panel .header {
    padding: 12px 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.86);
    background-color: #fafafa;
    border-radius: 4px 4px 0 0;
    font-weight: 500; }
    .panel .header.isNecessary::before {
      content: '*';
      color: red; }
  .panel .body {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 14px;
    border-radius: 0 0 4px 4px;
    overflow-wrap: break-word; }

.isNecessary::before {
  content: '*';
  color: red; }

.images {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.image {
  width: 110px;
  height: 110px;
  margin: 0px 16px 16px 0px;
  object-fit: cover; }

.scoreInput {
  display: flex;
  padding: 2px 4px;
  margin-right: 4px;
  height: 24px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center; }

.panel {
  position: relative;
}

.panel .editResultBtn {
  display: none;
  position: absolute;
  right: 16px;
  bottom: 12px;
  background-color: white;
}
.panel:hover .editResultBtn {
  display: block;
}

.deleteIconWrapper {
  display: none;
}
.imgWrapper:hover > .deleteIconWrapper {
  display: flex;
  background-color: rgba(0, 0, 0, 0.56);
}
