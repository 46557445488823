.wrapper {
  width: 70vw;
  max-width: 1024px;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; }

.top {
  padding: 24px 16px;
  height: 136px; }

.bottom {
  background-color: #FAFAFA;
  display: flex;
  flex: 1 1;
  height: 100%; }

.contentLeft {
  flex: 1 1;
  min-width: 490px;
  background: #fff;
  border-right: 1px solid #EDEDED; }

.contentRight {
  width: 30%;
  min-width: 260px;
  max-width: 350px;
  margin-left: 1rem;
  background: #fff;
  border-left: 1px solid #EDEDED; }

.noStepper {
  background: #FAFAFA;
  border-left: none; }

.noStepperContent {
  margin: 16px;
  height: 49px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px; }

.contentLeftHead {
  background-color: #FAFAFA;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  padding: 0px 16px; }

.contentRightHead {
  background-color: #FAFAFA;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  padding: 0px 16px; }

.contentLeftDetail {
  padding: 36px 16px; }

.contentLeftDetailBox {
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 16px 24px; }

.contentRightDetail {
  padding: 16px; }

.resetTransform {
  transform: initial !important; }
