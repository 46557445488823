@charset "UTF-8";
.sticky {
  position: sticky;
  top: 104px;
  z-index: 1;
  background-color: white;
  padding: 16px 16px 0px; }

.switchBtns {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding: 12px 4px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), transparent);
  border-radius: 4px; }

.transparentBtn {
  margin-right: 12px;
  padding: 2px 8px;
  font-size: 14px;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  opacity: 0.9; }
  .transparentBtn:hover {
    opacity: 1; }
  .transparentBtn:disabled {
    opacity: 0.5;
    cursor: auto; }

.videoStatus {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2f2f2f;
  color: white;
  border-radius: 4px; }

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
 }

.item {
  padding: 12px 16px;
  cursor: pointer; }
  .item:hover {
    background-color: #f9faf9; }
  .item.active {
    background-color: rgba(76, 175, 80, 0.06); }
  .item .activeTitle {
    color: #4caf50; }
  .item .answerTextBox {
    position: relative;
    border-radius: 2px;
    border: 1px solid #f6f6f6;
    background-color: #f9faf9; }
    .item .answerTextBox::before {
      content: '“';
      color: #e6e6e6;
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 3rem; }
  .item img {
    display: block;
    max-width: 33%;
    height: auto;
    margin: 12px 0px; }
  .item p:first-child {
    margin-top: 0; }

.videoRatio > :first-child {
  width: 100%; }

.videoRatio > img {
  height: auto; }

.videoRatio {
  position: relative; }

.videoRatio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (16 / 9)); }

.videoRatio > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; }
