.attachmentResume {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  text-align: left; }
  .attachmentResume .head {
    height: 40px;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #ededed;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    line-height: 16px;
    padding: 12px 15px; }
  .attachmentResume .body {
    width: calc(100% - 32px);
    margin: 12px 16px;
    display: flex;
    justify-self: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
    .attachmentResume .body .bodyHalf {
      width: calc(50% - 8px);
      margin-bottom: 16px; }
    .attachmentResume .body .bodyHalf:nth-child(odd) {
      margin-right: 8px; }
    .attachmentResume .body .bodyHalf:nth-child(even) {
      margin-left: 8px; }
