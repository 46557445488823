.wrapper {
  padding: 1rem;
  cursor: pointer; }
  .wrapper .question {
    display: flex;
    justify-content: space-between;
    color: #333; }
    .wrapper .question .goodStatus {
      display: flex;
      align-items: center;
      margin-left: 12px;
      padding: 2px 2px 2px 6px;
      font-size: 12px;
      background: linear-gradient(90deg, #63ecd4 0%, #36d7a4 100%);
      border-radius: 12px;
      cursor: pointer; }
      .wrapper .question .goodStatus .label {
        color: white; }
      .wrapper .question .goodStatus .icon {
        display: flex;
        align-items: center;
        margin-left: 4px;
        color: #3fdaae; }
    .wrapper .question .badStatus {
      display: flex;
      align-items: center;
      margin-left: 12px;
      padding: 2px 6px 2px 2px;
      font-size: 12px;
      background: linear-gradient(90deg, #c6cbce 0%, #9ea6ab 100%);
      border-radius: 12px;
      cursor: pointer; }
      .wrapper .question .badStatus .label {
        margin-left: 4px;
        color: white; }
      .wrapper .question .badStatus .icon {
        display: flex;
        align-items: center;
        color: #9fa7ac; }
    .wrapper .question .gameScore {
      margin-left: 12px;
      color: #7695cf; }
  .wrapper .answer {
    position: relative;
    display: flex;
    margin-top: 12px;
    background-color: #f9faf9;
    color: #9b9b9b;
    font-size: 12px;
    flex-direction: column; }
    .wrapper .answer .gameScore {
      color: #7695cf;
      font-weight: 400; }
    .wrapper .answer .quoteIcon {
      position: absolute;
      left: 3px;
      top: -12px;
      transform: rotate(180deg);
      color: #e6e6e6; }
    .wrapper .answer .goodStatus {
      display: flex;
      align-items: center;
      margin-left: 12px;
      padding: 2px 2px 2px 6px;
      font-size: 12px;
      background: linear-gradient(90deg, #63ecd4 0%, #36d7a4 100%);
      border-radius: 12px;
      cursor: pointer; }
      .wrapper .answer .goodStatus .label {
        color: white; }
      .wrapper .answer .goodStatus .icon {
        display: flex;
        align-items: center;
        margin-left: 4px;
        color: #3fdaae; }
    .wrapper .answer .badStatus {
      display: flex;
      align-items: center;
      margin-left: 12px;
      padding: 2px 6px 2px 2px;
      font-size: 12px;
      background: linear-gradient(90deg, #c6cbce 0%, #9ea6ab 100%);
      border-radius: 12px;
      cursor: pointer; }
      .wrapper .answer .badStatus .label {
        margin-left: 4px;
        color: white; }
      .wrapper .answer .badStatus .icon {
        display: flex;
        align-items: center;
        color: #9fa7ac; }
  /* .wrapper:hover {
    background-color: #f9faf9; } */
  /* .wrapper.active {
    background-color: rgba(75, 176, 81, 0.06); } */
  .wrapper.active > .question {
    color: #4bb051; }
  .wrapper.disabled {
    cursor: auto; }
