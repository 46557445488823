.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column; }

.full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c3c3c3;
  margin-top: 20%; }

.tabs {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.26);
  background-color: #fafafa;
  border: 1px solid #ededed;
  border-left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap; }
  .tabs div {
    padding: 12px 12px;
    cursor: pointer; }
  .tabs div:hover {
    color: rgba(0, 0, 0, 0.86); }

.sticky {
  position: sticky;
  top: 56px;
  z-index: 2; }

.active {
  color: rgba(0, 0, 0, 0.86);
  font-weight: 500; }

.content {
  position: relative;
  flex: 1;
  background-color: white;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed; }
