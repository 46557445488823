.mention {
  color:#3371ff;
  text-decoration: none;
  padding: 0.5px 2px;
  border-radius: 12px;
}

.mentionSuggestionsLimitedHeight {
  max-height: 300px;
  overflow: scroll;
  z-index: 1000;
  width: 218px;
}

.mentionSuggestionsEntry:active {
  background-color: #4caf50;
  color: white;
}

.mentionSuggestionsEntry:active>li>div>span {
  font-weight: 500  !important;
}

.mentionSuggestionsEntryFocused {
  background-color: #4caf50;
  color: white;

}
.mentionSuggestionsEntryFocused>li>div>span {
  font-weight: 500  !important;
}