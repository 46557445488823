.addDepartmentButton {
  width: 240px;
  height: 42px;
  border-radius: 21px;
  border: 1px solid #4bb051;
  font-size: 16px;
  font-weight: 500;
  color: #4bb051; }

.companyName {
  margin-top: 30px !important;
  padding-top: 3px;
  width: 100%;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56); }
  .companyName:hover {
    background-color: rgba(0, 0, 0, 0.04); }
